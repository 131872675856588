import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsCardReceivables } from '../data/terms/terms-card-receivables'

const TermsCardReceivablesPage = (): JSX.Element => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsCardReceivables}
      linkCanonical="termos-de-antecipacao-de-recebiveis-de-cartoes"
    />
  </>
)

export default TermsCardReceivablesPage
