import { TermsProps } from '../../@interface/terms'

export const dataTermsCardReceivables: TermsProps = {
  title:
    'Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis de Cartões”',
  update: '01 de outubro de 2021',
  about: {
    title: 'Termo',
    introduction: `
      Este documento (Condições Gerais da Operação de Desconto de Recebíveis de Cartões de
      Crédito - “Antecipação de Recebíveis de Cartões”) apresenta as condições de uso e regras
      gerais de contratação do produto de Operação de Desconto de Recebíveis de Cartões de
      Crédito - “Antecipação de Recebíveis de Cartões” disponibilizada pela Cora Sociedade de Crédito, Financiamento e Investimento S.A. - “CORA SCFI”., sociedade com sede na Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, no Município de São Paulo, Estado de São Paulo, inscrita no Cadastro
      Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o nº
      37.880.206/0001-63 (“CORA” ou “Nós”). <b>Você deve ler atentamente e por inteiro estas
      Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito -
      “Antecipação de recebíveis”, pois eles descrevem os termos, condições e regras gerais de
      contratação aplicáveis às empresas que pretendem realizar quaisquer Operações de
      Desconto - “Antecipação de Recebíveis” com a CORA.</b>
      <br />
      <br />
      <b>A realização de Operações de Desconto de Recebíveis de Cartão de Crédito -
      “Antecipação de Recebíveis” com a CORA implica sua total concordância com as
      disposições destas Condições.</b>
      <br />
      <br />
      Estas Condições Gerais são parte integral e inseparável (i) dos Termos e Condições Gerais
      de Uso da CORA, disponível em cora.com.br/termos-uso, e (ii) da Política de Privacidade da
      CORA, disponível em cora.com.br/politica-privacidade , de forma que a aceitação destas
      Condições Gerais implica, necessariamente, a aceitação dos Termos e Condições Gerais de
      Uso e da Política de Privacidade da CORA. Por este motivo, você deverá também ler
      atentamente esses instrumentos.
      <br />
      <br />
      Estas Condições Gerais também serão parte integrante e inseparável do instrumento
      específico de adesão a estas Condições Gerais no qual serão especificados os Recebíveis
      objeto de cada Operação de Desconto que vier a ser realizada com a CORA e a respectiva
      Taxa de Cessão, bem como IOF incidente.
      <br />
      <br />
      <b>
      Estas Condições Gerais da Operação de Desconto de Recebíveis de Cartões -
      “Antecipação de Recebíveis” poderão ser alteradas pela CORA a qualquer tempo. Você
      será previamente comunicado a respeito da realização de alterações e, caso não
      concorde com qualquer uma delas, não realizará novas operações de desconto de
      recebíveis de cartão de crédito junto à CORA.
      </b>
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          about:
            'Nesta seção, apresentamos o significado de alguns termos que serão utilizados ao longo destas Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis”',
          intro:
            'Nestas Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis”, os termos abaixo, quando iniciados em letras maiúsculas, deverão ter o seguinte significado:',
          items: [
            {
              item: `<b>Aplicações:</b> são aplicações de internet (como, por exemplo, site ou aplicativo mobile) ou outras ferramentas disponibilizadas pela CORA para que você possa gerenciar a Conta CORA de sua empresa e utilizar os Serviços;`,
            },
            {
              item: `<b>Bacen:</b> Banco Central do Brasil, órgão responsável por regular o funcionamento do mercado financeiro brasileiro;`,
            },
            {
              item: `<b>CET:</b> é o Custo Efetivo Total das operações de crédito vinculadas a realização da Operação de Desconto - “antecipação de recebíveis”, calculado e informado nos termos da regulamentação em vigor;`,
            },
            {
              item: `<b>IOF:</b> Imposto sobre Operações Financeiras, cobrado de pessoas físicas e jurídicas que praticarem operações de crédito, câmbio, seguro, ou relativas a títulos ou valores mobiliários;`,
            },
            {
              item: `<b>Canais de Comunicação:</b> são os canais oficiais para comunicação entre a CORA e você, para temas referentes ao uso dos Serviços aqui apresentados:
              <br />
              E-mail de atendimento: <a href="mailto:meajuda@cora.com.br">meajuda@cora.com.br</a>
              <br />
              Whatsapp oficial: (11) 93305-8198 e O chat do aplicativo.
              <br />
              O tempo de resposta para contatos via e-mail é de até 3 (três) dias úteis, assim, para atendimento em tempo real e pelo Whatsapp oficial;`,
            },
            {
              item: `<b>Conta CORA:</b> conta de pagamento digital pré-paga gerida pela CORA, de titularidade de Sua Empresa e movimentada por Aplicativo ou pelo Cartão na modalidade pré-paga com a finalidade de realizar pagamentos, de acordo com as regras destas Condições e dos Termos e Condições Gerais de Uso da CORA;`,
            },
            {
              item: `<b>Sua Empresa:</b> pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, elegível para realização de uma Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis”;`,
            },
            {
              item: `<b>Cedente:</b> é a Sua Empresa, pessoa jurídica titular de Créditos, oriundos das vendas de produtos e serviços realizados utilizando o cartão de crédito como instrumento de pagamento, que pretende cedê-los;`,
            },
            {
              item: `<b>Operação de Desconto de Recebíveis de Cartão de Crédito:</b> Operação de cessão definitiva dos Recebíveis presentes e futuros decorrentes de vendas realizadas no crédito que Sua Empresa pode realizar com a CORA. Neste documento, denominados como: “Operação de Desconto”, “Antecipação de Recebíveis”, “Antecipação”, “Cessão de Crédito” ou simplesmente “Cessão”;`,
            },
            {
              item: `<b>Recebível:</b> também chamados aqui de Créditos, são os direitos de crédito presentes e futuros, de titularidade da Sua Empresa, perante as Credenciadoras e/ou Subcredenciadoras, pelas vendas de produtos ou serviços que Sua Empresa realizou através do cartão de crédito do seu consumidor e que ainda serão pagos à Sua Empresa;`,
            },
            {
              item: `<b>Agenda de Recebíveis:</b> Conjunto de unidades de Recebíveis da Sua Empresa;`,
            },
            {
              item: `<b>Credenciadora e/ou Subcredenciadora:</b> também conhecidas como adquirente e/ou subadquirente. São instituições que credenciam Sua Empresa habilitando-a a realizar transações de venda de produtos e serviços a seus consumidores mediante utilização de cartão de crédito como meio de pagamento, ficando responsáveis por liquidar os Recebíveis gerados pela realização de tais transações;`,
            },
            {
              item: `<b>Arranjos de pagamento:</b> conjunto de regras voltado para transações de cartões de crédito, débito e pré-pago. Estas normas possibilitam que pessoas consigam realizar transações financeiras entre si utilizando o meio de pagamento relacionado àquele Arranjo;`,
            },
            {
              item: `<b>Domicílio bancário:</b> é a conta corrente de titularidade da Sua Empresa que Sua Empresa possui cadastrada em sua Credenciadora e/ou Subcredenciadora para receber as vendas realizadas por meio do cartão;`,
            },
            {
              item: `<b>Registradora:</b> Entidade autorizada pelo Banco Central do Brasil a realizar o registro dos Recebíveis no Sistema de Registro, dando visibilidade dos Recebíveis disponíveis no mercado para Operações de Desconto;`,
            },
            {
              item: `<b>Sistema de Registro:</b> é um sistema destinado ao registro de ativos financeiros, nos quais se incluem os Recebíveis da Sua Empresa, que serão liquidados pelas Credenciadoras e/ou Subcredenciadoras, operado por Registradoras;`,
            },
            {
              item: `<b>Interoperabilidade:</b> é a comunicação entre as Registradoras a fim de garantir que todo o mercado tenha visibilidade dos Recebíveis disponíveis a serem negociados, evitando assim, que um mesmo Recebível seja negociado mais de uma vez;`,
            },
            {
              item: `<b>Parceira:</b> empresas terceiras contratadas pela CORA que auxiliam ou viabilizam a prestação de serviços relacionados à “Antecipação de Recebíveis”;`,
            },
            {
              item: `<b>Preço da Cessão:</b> Valor dos Recebíveis vendidos, deduzido o CET da operação (o que inclui a Taxa da Cessão e o IOF);`,
            },
            {
              item: `<b>Taxa da Cessão:</b> Valor cobrado pela CORA para realizar a “Antecipação de Recebíveis”;`,
            },
            {
              item: `<b>Senha:</b> palavra-passe inserida por meio de digitação ou outro método de validação pessoal para a conclusão da “Antecipação de Recebíveis”;`,
            },
            {
              item: `<b>Tarifas:</b> valores cobrados pela Cora ou por Parceiras, quando aplicável;`,
            },
          ],
        },
        {
          head:
            'Contratação da Operação de Desconto de Recebíveis de Cartão de Crédito - “Antecipação de Recebíveis”',
          about:
            'São as condições aplicáveis ao cadastro da Sua Empresa e à contratação da Operação de Desconto de Desconto de Recebíveis de Cartão de Crédito - “Antecipação de Recebíveis”',
          intro:
            'A Cora poderá adquirir os Créditos que Sua Empresa pretenda ceder.',
          items: [
            {
              item:
                'A “Antecipação de Recebíveis” fica sujeita à prévia análise de crédito da CORA;',
            },
            {
              item:
                'A aprovação da “Antecipação de Recebíveis”, bem como a disponibilização do valor antecipado, dependerão de prévia análise da sua agenda de recebíveis e aprovação pela CORA;',
            },
            {
              item:
                'Eventual recusa da CORA na Antecipação de determinados Recebíveis não implica a rescisão destas Condições Gerais, que permanecerão válidas e vigentes com relação aos outros eventuais Recebíveis cedidos;',
            },
            {
              item:
                'Você autoriza, por tempo indeterminado, a CORA a verificar, por si ou através de suas Parceiras, a qualquer momento, sua Agenda de Recebíveis;',
            },
            {
              item:
                'Contratação da Operação de Desconto de Recebíveis de Cartão de Crédito. No ato de solicitação e contratação da Operação de Desconto, Sua Empresa deverá estar adequadamente representada, nos termos de seus atos constitutivos;',
              child: [
                {
                  item:
                    'A contratação poderá ser realizada diretamente pelas Aplicações;',
                },
                {
                  item:
                    'A "Antecipação” aprovada será formalizada via Aplicações da CORA, mediante instrumento específico de adesão a estas Condições Gerais que integrará estas Condições Gerais e no qual constarão as condições pactuadas, tais como: Taxa de Cessão e Preço da Cessão, IOF incidente, as datas de vencimento dos Recebíveis cedidos e a especificação dos Recebíveis cedidos;',
                },
                {
                  item:
                    'As “Antecipações de Recebíveis” incluem todos os direitos, prerrogativas e garantias que o acompanham, sendo Sua Empresa responsável pela existência e correta formalização dos Recebíveis;',
                },
                {
                  item:
                    'É condição para a “Antecipação” a troca da titularidade dos Recebíveis em favor da CORA, de modo que todos os Recebíveis cedidos por Sua Empresa relacionados no instrumento específico de adesão a estas Condições Gerais serão pagos à CORA;',
                },
                {
                  item:
                    'Com a troca da titularidade dos Recebíveis em favor da CORA em virtude da “Cessão”, os Recebíveis cedidos por Sua Empresa não serão pagos pelas credenciadoras / subcredenciadoras em seu Domicílio Bancário, mas sim em conta indicada pela CORA;',
                },
                {
                  item:
                    'Efetivação do Contrato. Nas contratações por meio eletrônico, ao digitar a senha do cartão e/ou senha eletrônica, você concorda com as Condições e termos da contratação e declara não ter dúvidas quanto ao conteúdo;',
                },
              ],
            },
            {
              item:
                'Você autoriza a CORA a enviar as informações da “Operação de Desconto” para o Sistema de Registro e autoriza a alteração de titularidade dos Recebíveis cedidos para a CORA, bem como a consequente especificação / alteração da instituição domicílio para liquidação financeira de tais Recebíveis.',
            },
            {
              item:
                'Na hipótese de indisponibilidade e impossibilidade de transferência de titularidade, para a CORA, no Sistema de Registro, de parte ou do todo de qualquer das unidades de Recebíveis discriminadas no instrumento específico da “Operação de Desconto”, Sua Empresa constitui a CORA, em caráter irrevogável e irretratável, como mandatária, com poderes para aditar o instrumento específico da “Operação de Desconto”, fazendo constar do aditamento apenas a parte ou o todo das unidades de Recebíveis discriminadas no instrumento específico originário que efetivamente estejam disponíveis para a consumação da “Operação de Desconto” e cuja transferência de titularidade para a CORA seja possível no Sistema de Registro, com os respectivos ajustes quanto ao Valor dos Recebíveis vendidos, CET e Preço de Cessão, sem alteração do percentual da Taxa de Cessão pactuado no instrumento específico originário.',
            },
          ],
        },
        {
          head: 'Custos Relacionados',
          about:
            'São informações sobre as Tarifas Aplicáveis à Operação de Desconto de Desconto de Recebíveis de Cartão de Crédito - “Antecipação de Recebíveis”',
          intro:
            'Uma vez que a CORA faz o pagamento antecipado pelos recebíveis, será cobrado uma Taxa da Cessão a ser indicada no instrumento específico em cada “Operação de Desconto”.',
          items: [
            {
              item:
                'Sua Empresa responde pelo pagamento da Taxa da Cessão, conforme políticas de precificação da CORA vigentes à época e condições estabelecidas entre as partes.',
              child: [
                {
                  item:
                    'A Taxa da Cessão incidirá da data da operação da “Antecipação” até a data de pagamento de cada Recebível originalmente acordada com as Credenciadoras / Subcredenciadoras (“Data de Vencimento do Crédito”).',
                },
                {
                  item:
                    'A CORA creditará o valor do Preço de Cessão na conta CORA da Sua Empresa após a confirmação da operação. O valor será correspondente ao total dos Recebíveis vendidos, já deduzido o CET da operação (o que inclui a Taxa da Cessão e o IOF), servindo o lançamento a crédito como plena, geral e irrevogável quitação de Sua Empresa à CORA.',
                },
              ],
            },
            {
              item:
                'Taxas. As taxas são sempre pré-fixadas da “Operação de Desconto”, apresentadas a Sua Empresa pelo aplicativo.',
            },
            {
              item: 'Tarifas.',
              child: [
                {
                  item:
                    'IOF. Conforme regulação vigente, há incidência de IOF na “Operação de Desconto”;',
                },
                {
                  item:
                    'CET. O CET de cada “Operação de Desconto” será discriminado a cada operação, em conformidade com o disposto em Resolução pelo Banco Central do Brasil;',
                },
                {
                  item:
                    'A CORA poderá instituir novas tarifas ou alterar tarifas a qualquer tempo, passando as alterações a vigorarem após o prazo fixado pelo Banco Central do Brasil.',
                },
              ],
            },
          ],
        },
        {
          head: 'Cancelamento da Operação',
          about: 'São apresentados as regras de cancelamento da Operação',
          intro:
            'Há a possibilidade do cancelamento da operação por duas vias. Uma por iniciativa da própria Cora e outra por iniciativa da Sua Empresa.',
          items: [
            {
              item:
                'Os casos de cancelamento da operação por iniciativa da própria CORA são apenas possíveis antes da confirmação da mesma. Ou seja, enquanto o pedido está sendo processado, é necessário que a CORA confirme com a registradora se os Recebíveis alvo daquela operação ainda continuam disponíveis. Caso algum dos Recebíveis não esteja mais disponível, a operação é cancelada, sendo necessária nova tentativa;',
            },
            {
              item:
                'O cancelamento originado pela Sua Empresa pode acontecer quando este se arrependeu da operação realizada, nos termos da legislação aplicável. Neste caso, Sua Empresa poderá solicitar o cancelamento em até 07 dias contados do recebimento do valor pago por seus Recebíveis, mediante a devolução dos valores pagos pela “Operação de Desconto” à CORA, acrescido de eventuais tributos e dos juros devidos até a data da efetiva devolução.',
              child: [
                {
                  item:
                    'Sua Empresa autoriza, neste ato, na hipótese de desistência por arrependimento da contratação e de modo irretratável e irrevogável, a CORA a debitar em conta de titularidade da Sua Empresa junto à CORA o valor pago por seus recebíveis, acrescido de eventuais tributos e dos juros devidos até a data da efetiva devolução.',
                },
              ],
            },
          ],
        },
        {
          head: 'Existência do Crédito e a Coobrigação pelo Pagamento',
          about: 'São apresentados as regras de coobrigação pelo pagamento',
          items: [
            {
              item:
                'Sua Empresa responde pela legalidade, veracidade, legitimidade e existência dos Recebíveis cedidos e, ainda, responde solidariamente pelo pagamento dos Créditos cedidos (Cessão com coobrigação), obrigando-se, no caso de não pagamento pelas Credenciadoras / Subcredenciadoras, por qualquer razão, inclusive mas não limitado a, caso de anulação, chargeback, cancelamento ou estorno das transações que originaram os Recebíveis, bem como falha no Sistema de Registros, a pagar à CORA o Preço da Cessão, acrescido de IOF incidente, juros à taxa estipulada no respectivo instrumento específico da “Operação de Desconto” , multa de 2% e juros moratórios de 1% ao mês, incidentes desde a data da “Operação” até a data do vencimento.',
            },
            {
              item:
                'Na hipótese de não pagamento à CORA da integralidade dos Recebíveis cedidos, por qualquer razão, Sua Empresa constitui a CORA, em caráter irrevogável e irretratável, até a liquidação de todas as operações de “Antecipação” contratadas com a CORA, como mandatária, com poderes para aditar o instrumento específico da “Operação de Desconto”, incluindo novos Recebíveis em substituição aos Recebíveis não liquidados em favor da CORA, em montante suficiente para o recebimento, pela CORA, do valor total dos Recebíveis originariamente negociados, sem qualquer ônus adicional à CORA.',
            },
            {
              item:
                'No caso da não existência de Recebíveis para suprir a dívida em sua totalidade ou parcialmente:',
              child: [
                {
                  item:
                    'Sua Empresa autoriza a CORA a compensar / debitar o valor devido e seus acréscimos com qualquer valor que Sua Empresa tenha depositado, empenhado ou entregue à CORA, a qualquer título, com preferência em relação a qualquer outro débito pendente de realização e/ou a realizar a compensação com quaisquer outros créditos que venham a ser devidos à Sua Empresa pela CORA ou pelas Credenciadoras. Essa autorização perdurará até a liquidação de todas as operações de “Antecipação” contratadas;',
                },
                {
                  item:
                    'Sua Empresa autoriza a CORA a emitir boletos para que pague seus valores devidos.',
                },
              ],
            },
            {
              item:
                'Sua Empresa responde integralmente por quaisquer despesas, inclusive de cobrança e honorários advocatícios, perdas ou prejuízos que a CORA vier a sofrer ou a realizar, em decorrência de medidas judiciais ou extrajudiciais, em razão das transações a que se referem os Recebíveis.',
            },
            {
              item:
                'A exclusão de um dos Recebíveis por ocasião de má formalização ou constituição não afeta o restante dos Recebíveis objeto da Cessão.',
            },
          ],
        },
        {
          head: 'Contato para Dúvidas e Solicitações',
          about: 'Como podemos nos comunicar',
          items: [
            {
              item:
                'Dúvidas e Solicitações. Qualquer dúvida ou solicitação deverá ser sempre encaminhada aos Canais de Comunicação oficiais da CORA e/ou de acordo com a forma descrita nestas Condições Gerais da Operação de Desconto de Recebíveis de Cartão de Crédito - "Antecipação de Recebíveis” ou nos Termos e Condições Gerais de Uso da conta CORA, de modo que qualquer outra forma de comunicação com a CORA e/ou suas Parceiras não será reconhecida;',
            },
            {
              item:
                'Comunicações CORA. Todas as comunicações realizadas pela CORA e/ou suas Parceiras com Sua Empresa serão feitas por meio das Aplicações ou das Informações de contato indicadas por Sua Empresa, sendo dela a responsabilidade exclusiva pelo recebimento e visualização dessas comunicações.',
            },
          ],
        },
        {
          head: 'Informações Adicionais',
          about:
            'Por fim, trazemos as cláusulas gerais destas Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito (Antecipação de Recebíveis)',
          items: [
            {
              item:
                'Limitação de responsabilidade. Sua Empresa reconhece que todo sistema que depende de rede mundial de computadores, tal como as Aplicações, está sujeito a falhas e indisponibilidade, e que, portanto, não acarretarão na responsabilização da CORA e/ou das Parceiras por eventuais prejuízos causados à Sua Empresa.',
            },
            {
              item:
                'Cookies. Ao acessar ou navegar pelas plataformas web da CORA você concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade',
            },
            {
              item:
                'Cessão. A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestas Condições Gerais da Operação de Desconto de Recebíveis de Cartão de Crédito - “Antecipação de Recebíveis” a qualquer pessoa, mediante simples notificação à Sua Empresa, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.',
            },
            {
              item:
                'Alterações nas Condições Gerais para Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis”. Essas Condições podem ser alteradas a qualquer tempo, mediante notificação prévia. Sua Empresa demonstrará que concorda com todas as modificações se continuar realizar Antecipações de seus Recebíveis com a CORA após a alteração das Condições. Caso não concorde com as novas Condições, Sua Empresa deverá parar de contratar novas “Antecipações de Recebíveis” com a CORA.',
            },
            {
              item:
                'Dados. Sua Empresa desde já autoriza a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras, pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário.',
            },
            {
              item:
                'Comunicação ao BACEN. Sua Empresa desde já declara estar ciente e de acordo que a CORA e/ou suas Parceiras poderão comunicar ao Banco Central do Brasil, ao Conselho de Controle de Atividades Financeiras (COAF) ou outros órgãos previstos pela legislação, as operações que possam estar configuradas na Lei no 9.613, de 3 de março de 1998, que dispõe sobre os crimes de “lavagem” ou ocultação de bens, direitos e valores, e demais disposições legais pertinentes à matéria, bem como quaisquer operações que possam gerar suspeitas de atos relacionados a fraude, financiamento do terrorismo, ilícitos cambiais ou quaisquer atos ilícitos.',
            },
            {
              item:
                'Prazo de Vigência. Estas Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis” e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação pela Sua Empresa.',
            },
            {
              item:
                'Lei Aplicável. Estas Condições Gerais da Operação de Desconto de Recebíveis de Cartões de Crédito - “Antecipação de Recebíveis” e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.',
            },
            {
              item:
                'Restrição preventiva. Fica desde já estabelecido que a CORA, restringe o acesso à “Operação de Desconto” apenas às pessoas com perfil de Titular ou Sócio da conta CORA da Sua Empresa.',
            },
            {
              item:
                'A CORA poderá ceder, transferir, empenhar ou, por qualquer outra forma, alienar ou dispor dos direitos e garantias referentes a estas Condições Gerais, independentemente de prévia comunicação ao Cedente;',
            },
            {
              item:
                'Os tributos devidos serão de exclusiva responsabilidade daquele que a lei definir como contribuinte.',
            },
            {
              item:
                'Fica eleita a Comarca da Capital do Estado de São Paulo para eventual discussão sobre as condições estabelecidas.',
            },
          ],
        },
      ],
    },
  ],
}
